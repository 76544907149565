export function acpDigitalWalletStates($stateProvider) {
  'ngInject';
  $stateProvider.state('digital-wallet', {
    parent: 'root',
    url: '/digital-wallet',
    data: {
      permissions: {
        only: 'digital_wallet_enabled',
        redirectTo: 'dashboard'
      }
    },
    resolve: {
      module($ocLazyLoad) {
        'ngInject';
        return import(/* webpackChunkName: "acp.section.digital-wallet" */ 'apps/digital-wallet').then(
          $ocLazyLoad.loadModule
        );
      }
    },
    template:
      '<acp-digital-wallet-information></acp-digital-wallet-information>'
  });
}

export default acpDigitalWalletStates;
